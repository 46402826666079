@tailwind base;
@tailwind components;
@tailwind utilities;

video.small-video::-webkit-media-controls-play-button {
  display: none !important;
}

video.small-video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
